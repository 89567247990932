import { Box, Divider } from "@chakra-ui/react"
import { GetServerSidePropsContext } from "next"
import { UserConfig } from "next-i18next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import dynamic from "next/dynamic"
import Head from "next/head"
import { useRouter } from "next/router"
import { ErrorBoundary } from "react-error-boundary"
import { namespaces } from "../../../domains.config"
import nextI18nextConfig from "../../../next-i18next.config"
import {
  HomepageConfigDocument,
  HomepageConfigDocumentData,
  HomepageConfigDocumentDataSlicesSlice,
} from "../../../prismicio-types"
import { Category } from "../../../src/api-clients/product-api-v1"
import { infoMessageStorage } from "../../../src/common/constants/browser-storage"
import {
  brands,
  categoryContentFilter,
  categoryTreeDepth,
  prismicSliceTypes,
  prismicTypes,
} from "../../../src/common/constants/constants"
import { getAllSeriesCategories, getAllSystemCategories } from "../../../src/common/hooks/queries"
import logger from "../../../src/common/lib/logger"
import { EXCEPTION_TYPES, traceException } from "../../../src/common/lib/trace"
import { getFullURL } from "../../../src/common/utils/common-utils"
import { getPrismicData } from "../../../src/common/utils/get-prismic-data"
import { handleSettledPromise } from "../../../src/common/utils/response-utils"
import CategoryTiles from "../../../src/components/features/homepage/CategoryTiles/CategoryTiles"
import TeaserCards from "../../../src/components/features/homepage/TeaserCards/TeaserCards"
import { ComponentError } from "../../../src/components/poc/Errors/ComponentError"
import { SectionTitle } from "../../../src/components/shared/SectionTitle"

/* Dynamically load the carousels components as they are fetched client side and not needed on the server */
const Novelties = dynamic(() => import("../../../src/components/features/homepage/Novelties"), {
  ssr: false,
})

const LastSeenItems = dynamic(() => import("../../../src/components/features/homepage/LastSeenItems"), {
  ssr: false,
})

const InfoMessage = dynamic(() => import("../../../src/components/features/homepage/InfoMessage/InfoMessage"), {
  ssr: false,
})

const Series = dynamic(() => import("../../../src/components/features/homepage/Series/Series"), {
  ssr: false,
})

type Props = {
  brand: string
  prismicPageData: HomepageConfigDocumentData
  systemCategories: Category[]
  seriesCategories: Category[]
  indexEnabled: string
  host: string
}

const HomePage = ({ brand, prismicPageData, systemCategories, seriesCategories, indexEnabled, host }: Props) => {
  const {
    title,
    subtitle,
    slices,
    show_system_categories,
    show_series,
    show_last_seen,
    show_novelties,
    show_teaser_card,
    show_info_message,
    show_all_categories,
  } = prismicPageData

  const messageProps =
    slices.find((slice: HomepageConfigDocumentDataSlicesSlice) => slice.slice_type === prismicSliceTypes.INFO_MESSAGE)
      ?.primary ?? {}
  const router = useRouter()
  const { locale, asPath } = router
  /* asPath returns "/" on the homepage, 
    so we need to pass an empty string go getFullURL,
    otherwise we get a discrepancy between the canonical and
    actual url 
  */
  const fullURL = getFullURL(host, locale, asPath === "/" ? "" : asPath)

  /* If we have the info message item set in localStorage and the Prismic toggle for info message is set to "false"
  deleted the info message item, so that the next info message will be visible by the user */
  if (typeof window !== "undefined") {
    if (!!localStorage.getItem(infoMessageStorage.KEY) && !!show_info_message === false)
      localStorage.removeItem(infoMessageStorage.KEY)
  }

  return (
    <>
      <Head>
        <title>{title}</title>
        {/* Apply this style specifically to this page to handle content shifting on scrollbar appearing/disappearing on reload of search results */}
        <style>{`
          body {
            height: calc(100vh + 0.5px);
          }
        `}</style>
        <meta name="robots" key="meta-robots" content={indexEnabled ? "index, follow" : "noindex, nofollow"} />
        {indexEnabled && <link rel="canonical" href={fullURL?.href} />}
      </Head>

      {/* Main and secondary homepage titles */}
      <Box mt="6" mb={{ base: "6", sm: !!show_system_categories ? "0" : "10" }} as="section">
        <SectionTitle
          title={title}
          fontSize={{ base: "5xl", sm: "7xl", md: "8xl" }}
          fontWeight={brands[brand].mainTitleFontWeight}
          as="h1"
          mb="0"
          textTransform="uppercase"
        />
        <SectionTitle
          title={subtitle}
          fontSize={{ base: "5xl", sm: "7xl", md: "8xl" }}
          fontWeight={brands[brand].subTitleFontWeight}
          mb="0"
          textTransform="uppercase"
        />
      </Box>

      {/* Info message */}
      {!!show_info_message && <InfoMessage messageProps={messageProps} />}

      {/* System categories tiles navigation */}
      {!!show_system_categories && !!systemCategories?.length && (
        <Box as="section">
          <CategoryTiles
            systemCategories={systemCategories}
            seriesCategories={seriesCategories}
            showAllCategories={!!show_all_categories}
            systemCards={
              slices.find(
                (slice: HomepageConfigDocumentDataSlicesSlice) => slice.slice_type === prismicSliceTypes.SYSTEM_CARD
              )?.items
            }
          />
        </Box>
      )}

      {/* Series carousel */}
      {!!show_series && !!seriesCategories?.length && (
        <ErrorBoundary FallbackComponent={ComponentError}>
          <Series seriesCategories={seriesCategories} />
        </ErrorBoundary>
      )}

      {/* Last seen carousel */}
      {!!show_last_seen && (
        <ErrorBoundary FallbackComponent={ComponentError}>
          <LastSeenItems />
        </ErrorBoundary>
      )}

      {/* Novelties carousel */}
      {!!show_novelties && (
        <ErrorBoundary FallbackComponent={ComponentError}>
          <Novelties />
        </ErrorBoundary>
      )}

      {/* Teaser cards */}
      {!!show_teaser_card && (
        <Box as="section">
          <Divider mt={{ base: "10", sm: "16", md: "20" }} mb={{ base: "12", md: "16" }} />

          <TeaserCards
            teaserCards={
              slices.find(
                (slice: HomepageConfigDocumentDataSlicesSlice) => slice.slice_type === prismicSliceTypes.TEASER_CARD
              )?.items
            }
          />
        </Box>
      )}
    </>
  )
}

export const getServerSideProps = async ({ locale, params, previewData }: GetServerSidePropsContext) => {
  const brand = params?.brand as string

  try {
    // Do a parallel request for all API calls
    const [systemCategoriesResult, seriesCategoriesResult, prismicHomepageResult, translationsResult] =
      await Promise.allSettled([
        getAllSystemCategories(locale, brand, categoryContentFilter.PRODUCTS),
        getAllSeriesCategories(locale, brand, categoryTreeDepth.FOUR),
        getPrismicData({ locale, brand, dataType: prismicTypes.HOMEPAGE, previewData }),
        serverSideTranslations(locale, namespaces, nextI18nextConfig as UserConfig),
      ])

    const prismicPageDataResponse = handleSettledPromise(
      prismicHomepageResult,
      `No Prismic home configuration data for ${locale}`
    ) as HomepageConfigDocument

    const systemCategoriesResponse = handleSettledPromise(
      systemCategoriesResult,
      `No Systems categories data for ${locale}`,
      false
    )

    const seriesCategoriesResponse = handleSettledPromise(
      seriesCategoriesResult,
      `No Series categories data for ${locale}`,
      false
    )

    const translations = handleSettledPromise(translationsResult, `No translations data for ${locale}`)

    return {
      props: {
        ...translations,
        prismicPageData: prismicPageDataResponse?.data,
        systemCategories: systemCategoriesResponse?.data?.subCategories ?? null,
        seriesCategories: seriesCategoriesResponse?.data?.subCategories ?? null,
      },
    }
  } catch (e) {
    logger.error(`Error fetching data for homepage: ${e}`)
    traceException({
      type: `${EXCEPTION_TYPES.OC_ERROR_EXCEPTION} for fetching data for homepage`,
      message: `Error fetching data for homepage; brand: ${brand}; locale: ${locale}: ${e}`,
      stack: e.stack,
    })
    return {
      redirect: {
        destination: `/${locale}/404`,
        permanent: false,
      },
    }
  }
}

export default HomePage
